import React from 'react'
import Titulo from '../../components/Titulo'


function CentroDatos() {
    return (
        <div className='productos-pages'>
            <Titulo titulo="Centro de Datos" />
            <div className='info'>
                <p>Nos permite ayudar a nuestros clientes en tareas tales como:</p>
            </div>

            <div className='lista'>
                <ol>
                    <li>Consultoría o Servicios Profesionales</li>
                    Ofrecemos servicios para la creación y mantenimiento de centro de datos.
                    <li>Servicios de Calidad</li>   
                    Ofrecemos servicios de calidad con un equipo responsable el cual garantiza la seguridad de la información,
                     nuestro equipo esta conformado por al menos: Ingenieros Especialista en Soluciones de Centro de Datos, Project Manager para la gestión de proyecto o servicio.            
                    <li>Sistema para levantar Tickets y buzón de quejas</li>
                    En Delfos369 nos esforzamos por brindar servicios y soluciones de calidad por lo cual podrás obtener acceso al sistema 
                    de tickets y buzón en dado caso de que lo requieras. (Aplica únicamente si el servicio es confirmado de compra). 
                </ol>
            </div>
        </div>
    )
}

export default CentroDatos