import React from 'react'
import Titulo from '../../components/Titulo'

function Noc() {
    return (
        <div className='productos-pages'>
            <Titulo titulo="NOC" />
            <div className='info'>
                <p>Nos permite ayudar a nuestros clientes en tareas tales como:</p>
            </div>

            <div className='lista'>
                <ol>
                    <li>Aprovisionamiento de dispositivos</li>
                    Ofrecemos servicios para la creación y mantenimiento de centro de datos.
                    <li>Actualización de Sistemas Operativos</li>
                    Ofrecemos servicios de calidad con un equipo responsable el cual garantiza la seguridad de la información,
                    nuestro equipo esta conformado por al menos: Ingenieros Especialista en Soluciones de Centro de Datos, Project Manager para la gestión de proyecto o servicio.
                    <li>Monitoreo Preventivo de fallas</li>
                    Un equipo responsable de dar soporte y monitoreo, constituido por al menos:
                    Gestor de Tickets
                    Tecnico de Soporte
                    Project Manager para gestión de proyecto o servicio

                    Sistema para levantar tickets y medio de contacto para levantar Quejas
                </ol>
            </div>
        </div>
    )
}

export default Noc