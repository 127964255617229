import React from 'react'

function Titulo(props) {
  return (
    <div>
           <div className="container">
                <div className="box">
                    <div className="title">
                        <span className="block"></span>
                        <h1 >{props.titulo}<span></span></h1>
                    </div>
                    <div className="role">
                        <div className="block"></div>
                        <p>Delfos369</p>
                    </div>
                </div>
            </div>



    </div>
  )
}

export default Titulo