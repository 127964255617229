import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import "./css/Comunidad.css"
/* ========================================= Importar Imagenes ========================================= */
import RutasPrivadas from "../assets/img/blog/rutasprivadas.png"
import BD from "../assets/img/blog/BD.png"
import UX from "../assets/img/blog/ux2.png"



function Comunidad() {
  return (
    <div className='comunidad'>
      <div className='card-group'>

        {/* ========================================== Titulo principal ========================================== */}
        <div className="container">
          <div className="box">
            <div className="title contacto">
              <span className="block"></span>
              <h1 >Comunidad<span></span></h1>
            </div>
            <div className="role">
              <div className="block"></div>
              <p>Delfos369.</p>
            </div>
          </div>
        </div>

        {/* =========================================== Blog Comunidad =========================================== */}
        <div className=''>

          <div className='miniatura'>
            <Card>
              <Card.Img variant="top" src={BD} alt={"Rutas Privadas React js - Roles"} />
              <Card.Body>
                <Card.Title>Base de Datos Procedimientos Almacenados</Card.Title>
                <Card.Text>
                  Descripción
                </Card.Text>
                <Card.Text>
                  Recomendaciones al utilizar procedimeintos almacenados.
                </Card.Text>
                <div className='boton-tutorial'>
                  <Link to="/bases-de-datos-procedimietos-almacenados-2022" className="btn" >EMPEZAR </Link>
                </div>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">By: Carlos Ulibarri - Desarrollador Back End</small>
              </Card.Footer>
              <div className='fecha'>
                <p>Publicado el 21 de Noviembre del 2022</p>
              </div>
              <div className='etiquetas'>
                <span>DBMS</span>
                <span>MySQL</span>
                <span>Oracle Database</span>
              </div>
              <div className='etiquetas'>
                <span>PostgreSQL</span>
                <span>SQL Server</span>
                <span>Back End</span>
              </div>
            </Card>
          </div>



          <div className='miniatura'>
            <Card>
              <Card.Img variant="top" src={RutasPrivadas} alt={"Rutas Privadas React js , Roles , Perfiles, 2022"} />
              <Card.Body>
                <Card.Title>Rutas públicas y privadas  React js 2022</Card.Title>
                <Card.Text>
                       Realiza un Programa con 
                        rutas publicas y privadas para "n" roles en React JS 2022 . 
                </Card.Text>
                <div className='boton-tutorial'>
                  <Link to="/rutas-privadas-reactjs" className="btn" >Empezar</Link>
                </div>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">By: Oscar Chávez - Desarrollador Front-end </small>
              </Card.Footer>
              <div className='fecha'>
                <p>21 de Diciembre del 2022</p>
              </div>
              <div className='etiquetas'>
                <span>React js</span>
                <span>Router Dom</span>
                <span>Rutas Privadas</span>
              </div>
            </Card>
          </div>



          <div className='miniatura'>
            <Card>
              {/* ============= Importar Imagen y poner en SRC , alt poner etiquetal para posicionamiento  ============= */}
              <Card.Img variant="top" src={UX} alt={"UX, UI , FRONTEND"} />
              <Card.Body>
                {/*===================== Titulo del Blog =====================*/}
                <Card.Title>Diseño de la experiencia de usuario: Frameworks de ux</Card.Title>
                {/*===================== Descripcion Breve del Blog =====================*/}
                <Card.Text>

                </Card.Text>
                {/* =====================  Botón  que redirigue al blog , enlazar con el APP la ruta ===================== */}
                <div className='boton-tutorial'>
                  <Link to="/framewords_UX" className="btn" >EMPEZAR </Link>
                </div>
              </Card.Body>
              {/* =================================== Creditos del creador del blog =================================== */}
              <Card.Footer>
                <small className="text-muted">By: Celeste Jímenez</small>
              </Card.Footer>

              {/* ======================================= Fecha de publicación  ======================================= */}
              <div className='fecha'>
                <p>Publicado el 20 de Diciembre del 2022</p>
              </div>

              {/* ======================================== etiquetas relevantes ======================================== */}

              <div className='etiquetas'>
                <span>UX</span>
                <span>Diseño de la experiencia de usuario</span>
                <span>Front-end</span>
              </div>
            </Card>
          </div>

          {/* ============================================== Inicia  ============================================== */}



          {/* =============================================  Termina  ============================================= */}


        </div>
      </div>
    </div>
  )
}

export default Comunidad