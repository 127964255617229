import React from 'react';
import "./blog.css";
import UXImg from "../../assets/img/blog/ux2.png";
import DCUImg from "../../assets/img/blog/DCU.jpeg";
import D5EImg from "../../assets/img/blog/D5E.jpeg";
import DTImg from "../../assets/img/blog/DT.jpeg";


function FrameworksUX() {
    return (
        <div className='blog'>
            {/* ======================================= Introducción al UX =======================================*/}

            {/* Titulo del Curso detro de la etiqueta h2 */}
            <div className='titulo'>
                <h2>Diseño de la experiencia de usuario: Frameworks de UX</h2>
            </div>

            <div className='box-info'>
                <div className='informacion'>
                    {/* Imagen de Portada del titulo dentro de la etiqueta img  */}
                    <div className='imagen'>
                        <img src={UXImg} alt="Diseño de la experiencia de usuario: Frameworks de ux" />
                        {/*Infomación : Nombre y Fecha de Publicación */}
                        <div className='info'>
                            <span>By: Reina Celeste Jiménez Hernández - Desarrollador</span>
                            <p>Publicado el 9 de diciembre del 2022</p>
                        </div>
                    </div>
                    <br />
                </div>
                <div className='Información'>
                    <h2>Requerimientos</h2>
                    {/*Listar requerimientos en etiquetas <li> */}
                    <div className='lista'>
                        <ol >
                            <li>Conocimientos: No se requiere experiencia previa.</li>
                            <li>Nivel: principiante</li>
                            <li>Idioma: español</li>
                        </ol>
                    </div>

                    {/*Poner texto relevante dentro de la etiqueta  P  - Titulos , Subtitulos <li> */}
                    {/* <p >Paso 1: Iniciar un nuevo proyecto en React</p> */}
                    {/*Poner texto relevante dentro de la etiqueta P <li> */}
                    {/* <span>Para ello  abrimos una terminal y ponemos los siguientes comando en el siguiente orden.</span>
                    <br /> */}

                    {/*Poner el codido dentro del div code , usando <span> para la informacion y <code>
                    cuando se pondra codigo*/}
                    <div className='code'>
                        {/* <span>Comando para crear proyecto en donde "my-app" es el nombre del proyecto</span>
                        <code>npx create-react-app my-app</code>
                        <br />
                        <span>Comando para entrar a la carpeta del proyecto</span>
                        <code> cd my-app</code>
                        <br />
                        <span>Comando para iniciar el proyecto</span>
                        <code> npm start</code>
                        <br /> */}
                        <span  >
                            Dentro de la mayoría de los procesos de la industria de desarrollo de software existen frameworks y procesos que ayudan a abordar el trabajo de manera mas eficiente y eficaz, para los diseñadores de UX representa una herramienta que acompaña el desarrollo desde la primera idea hasta el lanzamiento final del producto. Podemos definir framework como la estructura básica que enfoca y respalda el problema.
                        </span>
                        <span>
                            A continuación, revisaremos de manera breve los principales frameworks del Diseño UX.
                        </span>
                    </div>
                </div>
            </div>


            {/* ======================================= Inicio del Curso o Articulo  =======================================*/}

            {/* ======================================= Pantalla No dividida =======================================*/}

            <div className='inicio-tutorial'>
                <div>
                    <h2>Diseño centrado en el usuario.</h2>
                    {/*                 Si se desea cambiar de color la informacion de la etiqueta p poner en 
                className grees, red, blue */}
                    <p className=''>
                        Las fases de este proceso se centran en el usuario y sus necesidades Es un proceso iterativo, lo que significa que los diseñadores vuelven a ciertas fases varias veces para refinar sus diseños y crear el mejor producto posible para los usuarios previstos.
                    </p>
                    <div className="abs-center">
                        <img src={DCUImg} alt="Diseño centrado en el usuario" />
                    </div>
                    <p className='P.tabulacion'>
                        • Comprender cómo experimenta el usuario el producto u otros productos similares. Para logar esto es necesario hacer una labor de investigación exhaustiva mediante observación del usuario en diferentes entornos y contextos, entrevistas y otros instrumentos de investigación.
                    </p>
                    <p className='P.tabulacion'>
                        • Especificar las necesidades del usuario. De acuerdo a la investigación realizada en la fase anterior el diseñador debe ser capas de proporcionar una solución a cada uno de los problemas que a presentado el usuario.
                    </p >

                    <p className='tabulacion'>o Diseñar soluciones a esos problemas. Para poder realizar propuestas de prototipado es necesario empezar por una lluvia de ideas. </p>
                    <p className='tabulacion'>o	Test de soluciones de diseño con respecto a las necesidades de los usuarios. En este paso es necesario responder las siguientes preguntas: "¿El diseño resuelve el problema del usuario?". Para responderla es necesario hacer el test con usuarios reales.</p>
                    <h2>Los cinco elementos del diseño de UX.</h2>
                    <p className=''>
                        Los cinco elementos del diseño de UX son un framework de pasos que los diseñadores de UX realizan para transformar una idea en un producto funcional. Los cinco elementos son: estrategia, alcance, estructura, esqueleto y superficie. Considéralos un conjunto de cinco capas, en el que cada capa depende de la que está debajo.
                    </p>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm">
                                <p className='P.tabulacion'>
                                    • Estrategia: La capa inferior es la estrategia, donde se asientan las bases de tus objetivos de diseño. Estos objetivos se basan en las necesidades de los usuarios y los objetivos comerciales del producto.
                                </p>
                                <p className='P.tabulacion'>
                                    • Alcance: La siguiente capa es el alcance, donde se determina el tipo de producto que se está creando. En este punto, se debe tener en cuenta el tipo de características y contenido que se desea incluir en el producto.
                                </p>
                                <p className='P.tabulacion'>
                                    • Estructura: Es la capa intermedia es la estructura. Aquí, se pretende descubrir cómo organizar el diseño y cómo se quiere que los usuarios interactúen con el producto.
                                </p>
                                <p className='P.tabulacion'>
                                    • Esqueleto: El esqueleto es la maquetación del producto. La capa de esqueleto detalla cómo funciona el diseño y, al igual que en uno real, los usuarios no podrán ver su funcionamiento interno.
                                </p>
                                <p className='P.tabulacion'>
                                    • Superficie: Es la capa superior, la superficie, representa la apariencia del producto para los usuarios.
                                </p>
                            </div>
                            <div className="col-sm">
                            <div className="abs-center">
                            <img src={D5EImg} alt="Los cinco elementos del diseño de UX" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <p>
                        Si deseas mas informacion.Consulta el libro los cinco elementos de la experiencia del usuario, desarrollado originalmente por Jesse James Garret.
                    </p>
                    <h2>Design Thinking.</h2>
                    <p>
                        Design Thinking es un método centrado en el usuario para la resolución de problemas. Ayuda a los diseñadores a crear soluciones que aborden un problema real del usuario y que sean funcionales y razonables. Las fases de diseño son: empatizar, definir, pensar, crear prototipar y probar.
                    </p>
                    <div className="abs-center">
                        <img src={DTImg} alt="Design Thinking" />
                    </div>
                    <p className='P.tabulacion'>
                        • Empatizar:  el objetivo es comprender las necesidades de los usuarios, ¿Cómo piensan? y ¿Que sienten? Esto implica mucha investigación de usuarios, con encuestas, entrevistas y sesiones de observación, para que se pueda obtener una imagen clara de quiénes son los usuarios y los desafíos a los que se enfrentan.
                    </p>
                    <p className='P.tabulacion'>
                        • Definir: se busca crear una declaración clara del problema o una descripción de la necesidad de los usuarios que abordarán los diseños según los hallazgos de la investigación. Esto conducirá a el equipo hacia un objetivo claro para el diseño del producto.
                    </p>
                    <p className='P.tabulacion'>
                        • Pensar: El objetivo de esta fase es proponer la mayor cantidad de soluciones de diseño que sea posible.
                    </p>
                    <p className='P.tabulacion'>
                        • Prototipar: Cuando se sabe cómo resolver el problema, estará todo listo para pasar a esta fase. Un prototipo es un modelo inicial de un producto que demuestra su funcionalidad.
                    </p>
                    <p className='P.tabulacion'>
                    • Probar: esta fase inicia cuando los usuarios te hacen comentarios sobre el diseño antes de que los ingenieros desarrollen el producto y lo lancen al público. En este punto es importante usar estos comentarios para realizar cambios y mejoras en los diseños todas las veces que sea necesario. 
                    </p>
                    <p className='P.tabulacion'>
                    • Implementar:  los ingenieros desarrollan el producto y lo lanzan al público. Esta fase es el fin de un ciclo y la bandera de inicio para comenzar uno nuevo, perfeccionando y actualizando constantemente los productos es como se pretende mejorar cada vez un poco más. 
                    </p>
                    <br>
                    </br>
                </div>
            </div>
            <div>
            </div>
        </div>
    )
}

export default FrameworksUX;
