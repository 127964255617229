import React from 'react'
import { MDBFooter, MDBIcon } from 'mdb-react-ui-kit';

function FooterSmall() {
  return (
    <div className=''>
{/* ============================================= Footer Min ============================================= */}
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
        <div className='social-media na'>
          <a href='mailto:hola@delfos369.com?Subject=Información' target="_blank" rel="noreferrer" >
            <MDBIcon fab icon="google" />
          </a>
          <a href='https://www.instagram.com/delfos369/' target="_blank" rel="noreferrer" >
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='http://www.linkedin.com/company/delfos369' target="_blank" rel="noreferrer" >
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='https://github.com/delfos369' target="_blank" rel="noreferrer" >
            <MDBIcon fab icon="github" />
          </a>
        </div>
      </MDBFooter>
{/* ======================================== Aviso de Privacidad ======================================== */}
      <div className='aviso-privacidad'>
        <a href='/aviso-privacidad'>
          Aviso de Privacidad
        </a>

      </div>
    </div>
  )
}

export default FooterSmall