import Carousel from 'react-bootstrap/Carousel';
import './css/Productos.css';
import MEDCUR from "../assets/img/productos/medcur.png"
import MARVILOP from "../assets/img/productos/marvilop.png"

import { Link } from 'react-router-dom';




export function Productos() {
  return (
    <div className="portafolio">

      <div className="container">
        <div className="box">
          <div className="title">
            <span className="block"></span>
            <h1 >Productos<span></span></h1>
          </div>
          <div className="role">
            <div className="block"></div>
            <p>Delfos369</p>
          </div>
        </div>
      </div>


      <div className=''>
        <div className="producto-1">
          <div className="card sol">
            <div className="conten">
              <div className="front">
                <h3 className="title">Gestor Médico</h3>
                <div className="logo" >
                  <img src={MEDCUR} alt="" />
                </div>
              </div>


            </div>
          </div>
        </div>

        <div className="producto-1-texto"  >
          <div className="card2">
            <h1 className="card2title">Descripción</h1>
            <p className="card2content"> <b>Medcur</b> Medcur es un Software de gestión de procesos médicos,
              como lo es administrar tu personal en una clínica o hospital,
              así como el control de los expedientes médicos e historial clínico de tus pacientes.
            </p>

            <div className='boton-tutorial normal'>
              <Link to="/medcur" className="btn" >Ver más</Link>
            </div>


          </div>
        </div>




      </div>









      <div className=''>
        <div className="producto-2"  >
          <div className="card2">
            <h1 className="card2title">Descripción</h1>
            <p className="card2content">
              <b>Marvilop </b>te permitirá  realizar el proceso de preventa y ventas
              con una interfaz amigable para el usuario, en donde prodras controlar los diferentes
              menús con 3 tipos de roles diferentes, administrador, preventa y ventas.
            </p>
            <div className='boton-tutorial normal'>
              <Link to="/marvilop" className="btn" >Ver más</Link>
            </div>

          </div>
        </div>


        <div className="producto-1">
          <div className="card sol">
            <div className="conten">
              <div className="">
                <h3 className="title">Gestor de Preventa y Ventas</h3>
                <div className="logo" >
                  <img src={MARVILOP} alt="" />
                </div>
              </div>

              <div className="">
                <p className="description">
     
                </p>

              </div>
            </div>
          </div>
        </div>


      </div>







      {/*       <div className=''>
        <div className="producto-1">
          <div className="card">
            <div className="content">
              <div className="front">
                <h3 className="title">Marvilop</h3>
                <p className="subtitle">Gestor de Ventas</p>
              </div>

              <div className="back">
                <p className="description">
                  Marvilop te permitirá  realizar el proceso de preventa y ventas
                  con una interfaz amigable para el usuario, en donde prodras controlar los diferentes
                  menús con 3 tipos de roles diferentes, administrador, preventa y ventas.
                </p>
                <div className="boton-formulario">
                  <button type="submit">ver más Información</button>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="producto-1-texto"  >
          <div className="card2">
            <h1 className="card2title">Descripción</h1>
            <p className="card2content"> Software de gestión de procesos médicos,
              como lo es administrar tu personal en una clínica o hospital,
              así como el control de los expedientes médicos e historial clínico de tus pacientes.
            </p>
            <div className="card2form">

              <button className="sign-up"> Ver más</button>
            </div>
          </div>
        </div>
      </div>
 */}

    </div>



  );
}
