import { Icon } from '@iconify/react';
import quienes from "../assets/img/quienes.png";
import "./css/nosotros.css";
import "./css/Animacion.scss";





/* Seccion sobre Nosotros */
export function Nosotros() {
  return (
    <div className="nosotros">

      <div className='card-group'>
        <div className="container">
          <div className="box">
            <div className="title">
              <span className="block"></span>
              <h1 >Nosotros<span></span></h1>
            </div>
            <div className="role">
              <div className="block"></div>
              <p>Delfos369</p>
            </div>
          </div>
        </div>

        <div className='box-nosotros'>
          <div className="left">
            <div>
              <article>
                <b>Delfos369</b> es una empresa de desarrollo de software, utilizamos tecnologías descriptivas e innovadoras, para ayudarte a escalar tu negocio y reinventar tu organización.
                <span className="nowrap"></span>
              </article>
            </div>
          </div>

          <div className="right">
            <div className="nosotros-img">
              <img src={quienes} alt="DELFOS369" className="imagen-about-us" />
            </div>
          </div>
        </div>


        <div className='hola'>



          <div className='beneficios'>
            <div className="card-servicios">
              <div className="icon-servicios">
                <svg xmlns="http://www.w3.org/2000/svg" color="#fec63d" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
                  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z" />
                  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z" />
                  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z" />
                </svg>
              </div>
              <h2>Misión</h2>
              <div className="content-servicios">

                <p> Ayudar a nuestros clientes en el proceso de transformación digital,
                  desde la identificación de oportunidades de mejora,
                  creando aplicaciones y software de negocio de manera rápida y eficiente,
                  desarrollando y automatizando procesos de integración y liberación.</p>
              </div>
            </div>
          </div>


          <div className='beneficios'>
            <div className="card-servicios">
              <div className="icon-servicios">
                <svg xmlns="http://www.w3.org/2000/svg" color="#fec63d" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path fill="currentColor" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              </div>
              <h2>Visión</h2>
              <div className="content-servicios">
                <p>  Mostrar al mundo una manera diferente de percibir,
                  trabajar y vivir con tecnologías, logrando ser un referente en la provisión,
                  diseño, propuesta y desarrollo de soluciones tecnológicas disruptivas. </p>
              </div>
            </div>
          </div>


          <div className='beneficios'>
            <div className="card-servicios">
              <div className="icon-servicios">
                <svg xmlns="http://www.w3.org/2000/svg" color="#fec63d" fill="currentColor" class="bi bi-gem" viewBox="0 0 16 16">
                  <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z" />
                </svg>
              </div>
              <h2>Valores</h2>
              <div className="content-servicios">

                <p>Respeto</p>
                <p>Transparencia</p>
                <p>Innovación</p>
                <p>Agilidad</p>
                <p>Proactividad</p>
                <p>Justicia y Milla Extra</p>
              </div>
            </div>
          </div>



        </div>












        {/************************* * Graficas  ********************************/}


        {/************************* * Tecnologias   ********************************/}




        <div className="tecnologias">

          <div className="flex flex-column justify-center items-center  ">
            <div className="container">
              <div className="box">
                <div className="title">
                  <span className="block"></span>
                  <h1 >Tecnlogías<span></span></h1>
                </div>
                <div className="role">
                  <div className="block"></div>
                  {/*    <p>Delfos369</p> */}
                </div>

              </div>
            </div>


          





            <div className="slider-tecnologia">
  <div className="slide-track">
    <div className="slide">
<Icon icon="skill-icons:react-dark"   />
    </div>
    <div className="slide">
    <Icon icon="logos:angular" />
    </div>
    <div className="slide">
    <Icon icon="logos:html-5"   />

    </div>
    <div className="slide">
    <Icon icon="logos:css-3"  />
    </div>
    <div className="slide">
    <Icon icon="vscode-icons:file-type-scss"  />
    </div>
    <div className="slide">
    <Icon icon="icon-park:github" />
    </div>
    <div className="slide">
    <Icon icon="skill-icons:wordpress"  />
    </div>
    <div className="slide">
    <Icon icon="logos:mysql" />
    </div>
    <div className="slide">
    <Icon icon="logos:postgresql" />
    </div>
    <div className="slide">
    <Icon icon="logos:nodejs" />
    </div>
    <div className="slide">
    <Icon icon="logos:mongodb"  />
    </div>
    <div className="slide">
    <Icon icon="logos:java"  />
    </div>
    <div className="slide">
    <Icon icon="logos:git"   />
    </div>
    <div className="slide">
    <Icon icon="logos:mariadb"  />
    </div>
  </div>
</div>







            
          </div>
        </div>





        {/************************* * Parners  ********************************/}


        <div className="tecnologias partners">

          <div className="flex flex-column justify-center items-center  ">
            <div className="container">
              <div className="box">
                <div className="title">
                  <span className="block"></span>
                  <h1 >Partners<span></span></h1>
                </div>
                <div className="role">
                  <div className="block"></div>
                  {/*      <p>Delfos369</p> */}
                </div>
              </div>
            </div>

            

            <div className="slider-tecnologia">
  <div className="slide-track">
    <div className="slide">
    <Icon icon="logos:microsoft" color="#cf0a2c"  height="20px" className="icon-box" /> 
    </div>
    <div className="slide">
    <Icon icon="logos:aws" className="icon-box"  height="30px" />
    </div>
    <div className="slide">
    <Icon icon="cib:huawei" color="#cf0a2c"className="icon-box" />

    </div>
    <div className="slide">
    <Icon icon="logos:shopify" color="#cf0a2c" className="icon-box" />
    </div>
    <div className="slide">
    <Icon icon="cib:cisco" color="#049fd9"className="icon-box" />
    </div>



    <div className="slide">
    <Icon icon="logos:microsoft" color="#cf0a2c" height="20px" className="icon-box" /> 
    </div>
    <div className="slide">
    <Icon icon="logos:aws" className="icon-box"  height="30px" />
    </div>
    <div className="slide">
    <Icon icon="cib:huawei" color="#cf0a2c"className="icon-box" />

    </div>
    <div className="slide">
    <Icon icon="logos:shopify" color="#cf0a2c" className="icon-box" />
    </div>
    <div className="slide">
    <Icon icon="cib:cisco" color="#049fd9"className="icon-box" />
    </div>
 

 
  </div>
</div>












          </div>
        </div>





      </div>
    </div>
  );
}
