import { Icon } from '@iconify/react';
import './css/servicios.css';
import diseno from "../assets/img/log.png"
import FooterSmall from './components/FooterSmall';

export function Servicios() {


    function redirect() {
        window.location.href = "/contacto";
    }

    return (
        //titulo 
        <div className='servicios'>
            <div className="container">
                <div className="box">
                    <div className="title">
                        <span className="block"></span>
                        <h1 >Servicios<span></span></h1>
                    </div>
                    <div className="role">
                        <div className="block"></div>
                        <p>Delfos369</p>
                    </div>

                </div>
            </div>

            <div className=''>


                <div className='descripcion-servicios'>
                    <article>
                        Hay muchas razones por las cuales invertir en tecnología
                        en <b>Delfos369</b> podemos ayudarte a beneficiarte de todas
                        las bondades de la tecnología actual, para que tu negocio,
                        empresa u organización logren crecimiento, Contamos con un
                        equipo de análisis y programación que alcanza diferentes tecnologías
                        y herramientas de desarrollo permitiendo a nuestros clientes tener
                        una amplia gama de opciones para cubrir sus necesidades.

                    </article>
                </div>
                <div className='beneficios'>
                    <div className="card-servicios">
                        <div className="icon-servicios">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" color="#fec63d" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z" /></svg>
                        </div>
                        <h2>Aumenta tus Ventas</h2>
                        <div className="content-servicios">
                          
                            <p>Herramientas que permiten que nuestros clientes
                                generen cotizaciones y gestionen su área de ventas de manera óptima.</p>
                        </div>
                    </div>
                </div>


                <div className='beneficios'>
                    <div className="card-servicios">
                        <div className="icon-servicios">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" color="#fec63d" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM305.8 637.7c3.1 3.1 8.1 3.1 11.3 0l138.3-137.6L583 628.5c3.1 3.1 8.2 3.1 11.3 0l275.4-275.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6a8.03 8.03 0 0 0-11.3 0l-230 229.9L461.4 404a8.03 8.03 0 0 0-11.3 0L266.3 586.7a8.03 8.03 0 0 0 0 11.3l39.5 39.7z" /></svg>
                        </div>
                        <h2>Optimiza tus operaciones</h2>
                        <div className="content-servicios">  
                            <p>Desarrollo de software a la medida para control, supervisión
                                y alertamiento de sistemas, así como la gestión de la operación de la empresa. </p>
                        </div>
                    </div>
                </div>


                <div className='beneficios'>
                    <div className="card-servicios">
                        <div className="icon-servicios">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" color="#fec63d" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none"><rect width="40" height="32" x="4" y="8" stroke="currentColor" strokeLinejoin="round" strokeWidth="4" rx="3" /><path stroke="currentColor" strokeWidth="4" d="M4 11a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v9H4v-9Z" /><circle r="2" fill="currentColor" transform="matrix(0 -1 -1 0 10 14)" /><circle r="2" fill="currentColor" transform="matrix(0 -1 -1 0 16 14)" /></g></svg>
                        </div>
                        <h2>Diferenciate de la competencia</h2>
                        <div className="content-servicios"> 
                            <p>Diseño, desarrollo e implementación
                                de sitios webs dinámicos, aplicaciones móviles nativas, híbridas, web-app’s.</p>
                        </div>
                    </div>
                </div>

            </div>





            <div className=''>


                <div className="container-s">
                    <div className="card-s">
                        <h2 className="title-s">Consultoría e implementación</h2>
                        <div className="card-hidden-s">
                            <h3>Consultoría e implementación</h3>
                            <p>Evaluamos los requerimientos de nuestros clientes de manera
                                metódica y precisa, para poder ofrecer soluciones de desarrollo
                                de software a la medida, adaptando el proyecto a las necesidades
                                de nuestros clientes, tomando en cuenta la optimización de
                                recursos, ofreciendo soluciones escalables y adaptativas.
                            </p>
                            <div className='button-servicios'>
                                <button className='' onClick={redirect}>Más Información</button>
                            </div>

                        </div>
                    </div>
                    <div className="card-border-s" />
                </div>






                <div className="container-s">
                    <div className="card-s">
                        <h2 className="title-s">Sitios web</h2>
                        <div className="card-hidden-s">
                            <h3>Sitios web</h3>
                            <p>    Nuestro servicio de creación de Páginas Web
                                permite que nuestros clientes tengan presencia
                                en el mundo virtual, ofreciendo páginas web
                                responsivas lo cual permite adaptarse a cualquier
                                dispositivo móvil ya sea tablets, smartphones,
                                computadoras y tvs, cubriendo cualquier tipo de
                                nicho de mercado que usted requiera. </p>

                            <div className='button-servicios'>
                                <button className='' onClick={redirect}>Más Información</button>
                            </div>

                        </div>
                    </div>
                    <div className="card-border-s" />
                </div>


                <div className="container-s">
                    <div className="card-s">
                        <h2 className="title-s">Desarrollo de Software</h2>
                        <div className="card-hidden-s">
                            <h3>Desarrollo de Software</h3>
                            <p>                                    Evaluamos los requerimientos de nuestros clientes de manera metódica y precisa, para poder ofrecer soluciones de desarrollo de software a la medida, adaptando el proyecto a las necesidades de nuestros clientes, tomando en cuenta la optimización de recursos, ofreciendo soluciones escalables y adaptativas.

                            </p>
                            <div className='button-servicios'>
                                <button className='' onClick={redirect}>Más Información</button>
                            </div>

                        </div>
                    </div>
                    <div className="card-border-s" />
                </div>


                <div className="container-s">
                    <div className="card-s">
                        <h2 className="title-s">Consultoría en bases de datos</h2>
                        <div className="card-hidden-s">
                            <h3>Consultoría en bases de datos</h3>
                            <p>                                      Complementamos a su equipo de IT a diseñar, planificar implementar y ejecutar, los procesos necesarios para migrar sus datos a una arquitectura de base de datos.


                            </p>
                            <div className='button-servicios'>
                                <button className='' onClick={redirect}>Más Información</button>
                            </div>

                        </div>
                    </div>
                    <div className="card-border-s" />
                </div>




            </div>












            <div className='contenedor-caracteristicas'>

            </div>

            <div className='contenedor-descubre-beneficios'>
                <div className='img-beneficios'>
                    <img src={diseno} alt="beneficios" ></img>
                </div>

                <div className='informacion-beneficios'>
                    <h2 className="titulo-info-beneficios">Beneficios de trabajar con nosotros</h2>
                    <h3 className="subtitulo-info-beneficios">Nuestro método de trabajo nos permite concluir de manera eficiente y exitosa tu proyecto.</h3>

                    <div className='contenedor-iconos-info'>
                        <div>

                            <div className='icono-beneficios'>
                                <Icon icon="icon-park-solid:user-business" color="#3b4754" width="40" height="40" />
                            </div>

                            <div className='icono-beneficios'>
                                <Icon icon="clarity:cursor-hand-click-line" color="#3b4754" width="40" height="40" />
                            </div>

                            <div className='icono-beneficios'>
                                <Icon icon="ant-design:line-chart-outlined" color="#3b4754" width="40" height="40" />
                            </div>
                        </div>
                        <div className='contenedor-informacion-beneficios-caracteristicas'>
                            <div className='informacion-beneficios-caracteristicas'>
                                <p id='informacion'>Consultoría y acompañamiento durante tu proceso de transformación sigital.</p>
                            </div>
                            <div className='informacion-beneficios-caracteristicas'>

                                <p id='informacion'>Definimos tu estrategía de modelo de .</p>
                            </div>
                            <div className='informacion-beneficios-caracteristicas'>

                                <p id='informacion'>Trabajamos con métodos ágiles  que nos permiten alcanzar resultados a corto plazo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSmall />

        </div>
    );

}
