import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';
import "./pages/css/Animaciones.css"
import Menu from './pages/shared/header/Menu';
import Slider from './pages/shared/slider/Slider';
import Footer from './pages/shared/footer/Footer';
import FooterSmall from './pages/components/FooterSmall';
import { Nosotros } from './pages/Nosotros';
import ScrollToTop from './ScrollToTop';
import { Servicios } from './pages/Servicios';
import { Productos } from './pages/Productos';
import { Contacto } from './pages/Contacto';
import NotFound from './pages/NotFound';
import Privacidad from './pages/AvisoPrivacidad';
import Comunidad from './pages/Comunidad';
import Medcur from './pages/productos/Medcur';
import Marvilop from './pages/productos/Marvilop';
import ProcedimientosAlmacenados from './pages/blog/BDProdecimientosAlmacenados';
import FrameworksUX from './pages/blog/FrameworksUX';
import RutasPrivadas from './pages/blog/RutasPrivadas';
import ProductosMenu from './pages/productos/Productos';
import CentroDatos from './pages/productos/Soluciones/CentroDatos';
import SolucionesRed from './pages/productos/Soluciones/SolucionesRed';
import SolucionesCorporativa from './pages/productos/Soluciones/SolucionesCorporativa';
import GobiernoDigital from './pages/productos/Soluciones/GobiernoDigital';
import SeguridadAnalitica from './pages/productos/Soluciones/SeguridadAnalitica';
import SolucionesCiberseguridad from './pages/productos/Soluciones/SolucionesCiberseguridad';
import SolucionesSeguridad from './pages/productos/Soluciones/SolucionesSeguridad';
import Noc from './pages/productos/Soluciones/Noc';













/* Estructura de la página web y Rutas */
function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <main>
          <Switch>
            <Route exact path="/">  <Menu /> <Slider />  <Nosotros />   <Footer />  </Route>
            <Route exact path="/aviso-privacidad"> <Menu />  <Privacidad />   </Route>
            <Route exact path="/comunidad">  <Menu />  <Comunidad />   </Route>
            <Route exact path="/contacto"> <Menu />  <Contacto />   </Route>
            <Route exact path="/nosotros"> <Menu />  <Nosotros />  <FooterSmall /> </Route>
            <Route exact path="/soluciones"> <Menu />  <Productos />   </Route>
            <Route exact path="/servicios"> <Menu />  <Servicios />   </Route>

            {/*        bases-de-datos-procedimietos-almacenados-2022 */}




            {/* ============================================= Productos ============================================= */}
            <Route exact path="/productos"> <Menu />  <ProductosMenu />   </Route>
            <Route exact path="/medcur"> <Menu />  <Medcur />   </Route>
            <Route exact path="/marvilop"> <Menu />  <Marvilop />   </Route>


           {/*      Productos Paginas */}

            <Route exact path="/centro-datos"> <Menu />  <CentroDatos />   </Route>
            <Route exact path="/soluciones-red"> <Menu />  <SolucionesRed />   </Route>
            <Route exact path="/soluciones-corporativa"> <Menu />  <SolucionesCorporativa />   </Route>
            <Route exact path="/soluciones-ciberseguridad"> <Menu />  <SolucionesCiberseguridad />   </Route>
            <Route exact path="/soluciones-seguridad"> <Menu />  <SolucionesSeguridad />   </Route>
            <Route exact path="/soluciones-analitica"> <Menu />  <SeguridadAnalitica />   </Route>
            <Route exact path="/noc"> <Menu />  <Noc />   </Route>
            <Route exact path="/gobierno-digital"> <Menu />  <GobiernoDigital />   </Route>


            {/* ============================================= Comunidad ============================================= */}

            <Route exact path="/bases-de-datos-procedimietos-almacenados-2022">  <Menu />  <ProcedimientosAlmacenados /></Route>
            <Route exact path="/framewords_UX">  <Menu />  <FrameworksUX/>   </Route>
            <Route exact path="/rutas-privadas-reactjs">  <Menu />  <RutasPrivadas />   </Route>

            
            <Route component={NotFound} />
          </Switch>
          <ScrollToTop />
        </main>
      </Router>



    </div>
  );
}
export default App;
