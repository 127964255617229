import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

import "./footer.css";
function Footer() {
  return (


    <div className='footer'>
      {/* ============================================ Footer Home ============================================ */}

      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>

        <div className='social'>
          <p>Redes Sociales :</p>
        </div>


        <div className='social-media'>
          <a href='mailto:hola@delfos369.com?Subject=Información' target="_blank" rel="noreferrer" >
            <MDBIcon fab icon="google" />
          </a>
          <a href='https://www.instagram.com/delfos369/' target="_blank" rel="noreferrer">
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='http://www.linkedin.com/company/delfos369' target="_blank" rel="noreferrer" >
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='https://github.com/delfos369' target="_blank" rel="noreferrer">
            <MDBIcon fab icon="github" />
          </a>
        </div>


        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>
                  Delfos369
                </h6>
                <p>
                  Somos  una empresa de de desarrollo de software,
                  utilizamos tecnologías disruptivas e innovadoras,
                  para ayudarte a escalar tu negocio y reinventar tu organización.
                </p>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Productos</h6>
                <p>
                  <a href='/medcur' className='text-reset'>
                    Gestión Medica
                  </a>
                </p>
                <p>
                  <a href='/marvilop' className='text-reset'>
                    Gestion proceso de Ventas
                  </a>
                </p>
                <p>

                </p>
                <p>
                  <a href='/servicios' className='text-reset'>
                    Desarrollo web
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>

                <div className='useful'>


                  <h6 className='text-uppercase fw-bold mb-4'>Links</h6>
                  <p>

                  </p>
                  <p>
                    <a href='/comunidad' className='text-reset'>
                      Comunidad
                    </a>
                  </p>
                  <p>
                    <a href='/soluciones' className='text-reset'>
                      Soluciones
                    </a>
                  </p>
                  <p>
                    <a href='/nosotros' className='text-reset'>
                      Nosotros
                    </a>
                  </p>

                </div>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Contacto</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  Avenida Santa Fe 505, Piso 1 Mezzanine 2B. Santa Fe Cuajimalpa.
                  Cuajimalpa de Morelos, Ciudad de México. C.P. 05348
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                   hola@delfos369.com
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" />
                   +521 55-68-14-80-78
                </p>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className='text-center p-4'>
          <a className='footer' href='https://delfos369.com/'>
            DELFOS369 S.A. de C.V.
          </a>
        </div>
      </MDBFooter>


      <div className='aviso-privacidad'>
        <a href='/aviso-privacidad'>
          Aviso de Privacidad
        </a>

      </div>




    </div>
  )
}

export default Footer