import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "../../css/Slider.css"
import Slider1 from "../../../assets/img/slider1.png"
import Slider2 from "../../../assets/img/slider2.png"
import Slider3 from "../../../assets/img/slider3.png"

function Slider() {
  return (
    <div  className='slider'>
          <div  className='slider-box'>
    <Carousel>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={Slider1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Innovando el Mundo del Desarrollo de Software</h3>
          <p>DELFOS369 S.A. DE C.V.</p>

          
          <div className="boton-formulario">
                <a href="/soluciones" className="btn-formuario"> ver más </a>
              </div>

              
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={Slider3}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h3>MEDCUR</h3>
          <p>El Software Técnologico que necesitas.</p>

              <div className="boton-formulario">
                <a href="/medcur"  className="btn-formuario"> ver más</a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={Slider2}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3>MARVILOP</h3>
              <p>
                Agiliza tu producción mediante el CRM Marvilop
              </p>

              <div className="boton-formulario">
                <a href="/marvilop" className="btn-formuario"> ver más </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </div>
    </div>
  )
}

export default Slider

