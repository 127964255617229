import React from 'react';
import "./blog.css";
import BDImg from "../../assets/img/blog/BD.png";

function ProcedimientosAlmacenados() {
    return (
        <div className='blog'>

            {/* ======================================= Introducción al Curso =======================================*/}

            {/* Titulo del Curso detro de la etiqueta h2 */}
            <div className='titulo'>
                <h2>Bases de Datos: Procedimientos Almacenados</h2>
            </div>

            <div className='box-info'>
                <div className='informacion'>
                    {/* Imagen de Portada del titulo dentro de la etiqueta img  */}
                    <div className='imagen'>
                        <img src={BDImg} alt="Procedimientos almacenados MySQL phpMyAdmin Workbench SQL Developer Oracle PostgreSQL pgAdmin 4 SQL Server " />
                        {/*Infomación : Nombre y Fecha de Publicación */}
                        <div className='info'>
                            <span>By: Carlos Ulibarri - Desarrollador Back End</span>
                            <p>Publicado el 21 de Noviembre del 2022</p>
                        </div>
                    </div>
                    <br />
                </div>
                <div className='requerimientos'>
                    <h2>Requerimientos</h2>
                    {/*Listar requerimientos en etiquetas <li> */}
                    <div className='lista'>
                        <ol >
                            <li>phpMyAdmin / XAMPP</li>
                            <li>Workbench</li>
                            <li>pgAdmin 4</li>
                            <li>Oracle DataBase XE / SQL Developer</li>
                            <li>SQL Server</li>
                        </ol>
                    </div>

                    {/*Poner texto relevante dentro de la etiqueta  P  - Titulos , Subtitulos <li> */}
                    {/* <p >Paso 1: Iniciar un nuevo proyecto en React</p> */}
                    {/*Poner texto relevante dentro de la etiqueta P <li> */}
                    {/* <span>Para ello  abrimos una terminal y ponemos los siguientes comando en el siguiente orden.</span>
                    <br /> */}

                    {/*Poner el codido dentro del div code , usando <span> para la informacion y <code>
                    cuando se pondra codigo*/}
                    <div className='code'>
                        {/* <span>Comando para crear proyecto en donde "my-app" es el nombre del proyecto</span>
                        <code>npx create-react-app my-app</code>
                        <br />
                        <span>Comando para entrar a la carpeta del proyecto</span>
                        <code> cd my-app</code>
                        <br />
                        <span>Comando para iniciar el proyecto</span>
                        <code> npm start</code>
                        <br /> */}
                        <span>
                            Como sabemos, un procedimiento almacenado o store procedure, es un conjunto de instrucciones en SQL, manipulando estas instrucciones con los sublenguajes de este mismo (DDL, DML, TCL, DCL).
                        </span>

                        <span>
                            Sus mayores ventajas es brindar mayor seguridad y rendimiento, solo cabe destacar que hay que tener cuidado con no sobresaturar el servidor con una gran cantidad de procedimientos almacenados,
                            ya que generará retrasos al realizar los procesos correspondientes. Es recomendable analizar qué procesos son los más necesarios que podrían ayudar a optimizar el rendimiento del desarrollo y
                            que no implique una implementación demasiado compleja, como lo es una lógica de negocio.
                        </span>
                    </div>
                </div>
            </div>



            {/* ======================================= Inicio del Curso o Articulo  =======================================*/}

            {/* ======================================= Pantalla No dividida =======================================*/}

            <div className='inicio-tutorial'>
                <div>
                    <h2>Sintaxis al crear procedimientos almacenos entre los DBMS</h2>
                    {/*                 Si se desea cambiar de color la informacion de la etiqueta p poner en 
                className grees, red, blue */}
                    <p className=''>
                        Regularmente cuando recién se va aprendiendo sobre bases de datos, se idealiza que la sintaxis de las sentencias SQL en todos los DataBase Management System (DBMS) / Sistema de Gestión de Bases de Datos (SGBD) es la misma,
                        y al momento de cambiar de un gestor a otro, provocará confusión y hasta incluso frustación al estar utilizando una sintaxis diferente. A continuación se muestra la sintaxis de diferentes gestores:
                    </p>

                    <div className='code-2'>
                        {/*          Inormacion antes de indicar codigo */}
                        <span className='tecnologias'>MySQL (phpMyAdmin/Workbench)</span>
                        {/*       Poner el codigo en la etiqueta P con la className code*/}
                        <p className=''>
                            <b className='code-color'> CREATE PROCEDURE</b> createUsers
                        </p>
                        <p className='tabulacion'>
                            ( <b className='code-color'>IN</b> userEmail <b className='code-color-variable'>VARCHAR(100)</b>,
                            <br />
                            <b className='code-color'>IN</b>  userPassword <b className='code-color-variable'>VARCHAR(100)</b>,
                            <br />
                            <b className='code-color'>IN</b> userName  <b className='code-color-variable'>VARCHAR(100))</b>
                        </p>




                        <p className=''>
                            <b className='code-color'>  BEGIN</b>
                        </p>
                        <p className='tabulacion'>
                            <b className='code-color'>   DECLARE </b>   lastInsertUser  <b className='code-color-variable'> INT (10);</b>
                            <br />
                            <b className='code-color'>   INSERT INTO </b>  users;
                            <br />
                            <b className='tabulacion-doble'>(user_email,user_password,user_name)</b>

                            <br />
                            <b className='code-color'>  VALUES </b>
                            <br />
                            <b className='tabulacion-doble'>(userEmail,userPassword,userName);</b>
                            <br />
                            <b className='code-color'>  SET </b> lastInsertUser  <b className='code-color'>= (SELECT</b> LAST_INSERT_ID());
                        </p>

                        <p className=''>
                            <b className='code-color'>  END</b>
                        </p>

                        {/* SQL DEVELOPER */}
                        <span>
                            En ocasiones al agregar BEGIN y END en phpMyAdmin generará error, así que se puede ejecutar sin estos mismos, pero es recomendable agregarlos para buenas prácticas.
                        </span>
                        <br />
                        <span className='tecnologias'>Oracle (SQL Developer)</span>
                        {/*       Poner el codigo en la etiqueta P con la className code*/}
                        <p className=''>
                            <b className='code-color'>   CREATE PROCEDURE</b>    createUsers
                        </p>
                        <p className='tabulacion'>
                            ( <b className='code-color'>IN</b> userEmail <b className='code-color-variable'>VARCHAR(100)</b>,
                            <br />
                            <b className='code-color'>IN</b>  userPassword <b className='code-color-variable'>VARCHAR(100)</b>,
                            <br />
                            <b className='code-color'>IN</b> userName  <b className='code-color-variable'>VARCHAR(100))</b>
                        </p>

                        <p>
                            <b className='code-color'> DECLARE</b>
                        </p>
                        <p className='tabulacion'>
                            lastInsertUser   <b className='code-color-variable'>INT (10);</b>
                        </p>

                        <p>
                            <b className='code-color'>BEGIN</b>
                        </p>

                        <p className='tabulacion'>
                            <b className='code-color'> INSERT INTO</b> users
                            <br />
                            <b className='tabulacion-doble'> INSERT INTO(user_email,user_password,user_name)</b>
                            <br />
                            <b className='code-color'> VALUES</b>
                            <br />
                            <b className='tabulacion-doble'>(userEmail,userPassword,userName);</b>
                            <br />
                            <b className='code-color'> SELECT </b> LAST_INSERT_ID() <b className='code-color'>INTO</b>  lastInsertUser;
                        </p>

                        <p>
                            <b className='code-color'> END</b>  createUsers;
                        </p>

                        {/*        PostgreSQL (pgAdmin 4) */}

                        <span className='tecnologias'>PostgreSQL (pgAdmin 4)</span>
                        <br />

                        {/*       Poner el codigo en la etiqueta P con la className code*/}
                        <p className=''>
                            <b className='code-color'>  CREATE PROCEDURE </b> reateUsers
                        </p>

                        <p className='tabulacion'>
                            (userId INOUT INTEGER,
                            <br />
                            userEmail <b className='code-color-variable'>VARCHAR(100))</b>,
                            <br />
                            userPassword  <b className='code-color-variable'>VARCHAR(100)</b>,
                            <br />
                            userName  <b className='code-color-variable'>VARCHAR(100)</b>
                            <br />
                            LANGUAGE plpgsql AS
                        </p>

                        <p className=''>
                            <b className='code-color'> BEGIN </b>
                        </p>

                        <p className=''>
                            <b className='code-color'>  INSERT INTO </b> users
                        </p>
                        <p className='tabulacion'>
                            (user_email,user_password,user_name)
                        </p>
                        <p className=''>
                            <b className='code-color'>   VALUES </b>
                        </p>
                        <p className='tabulacion'>
                            (userEmail,userPassword,userName);
                        </p>
                        <p className=''>
                            <b className='code-color'>  RETURNING </b>   user_id  <b className='code-color'> INTO </b> userId;
                        </p>
                        <p className=''>
                            <b className='code-color'>END </b>
                        </p>

                        <span>
                            Con este gestor se debe de tener cuidado con los tipos de datos, ya sea al declarar una variable o incluso al crear los atributos de una tabla,
                            como es en el caso del tipo INT, aquí debe ser nombrado como INTEGER.
                        </span>


                        {/* SQL SERVER  */}
                        <span className='tecnologias'>SQL Server </span>
                        {/*       Poner el codigo en la etiqueta P con la className code*/}
                        <br />
                        <p className=''>
                            <b className='code-color'> CREATE PROCEDURE </b>   createUsers
                        </p>
                        <p className='tabulacion'>
                            (@userId INT OUT,
                            <br />
                            @userEmail NVARCHAR(100),
                            <br />
                            @userPassword NVARCHAR(100),
                            <br />
                            @userName NVARCHAR(100))
                        </p>

                        <p className=''>
                            <b className='code-color'> AS  </b>
                        </p>
                        <p className=''>
                            <b className='code-color'> BEGIN  </b>
                        </p>
                        <p className=''>
                            <b className='code-color'>  SET </b> @userId = (INSERT INTO users
                        </p>
                        <p className='tabulacion'>
                            (user_email,user_password,user_name)
                        </p>
                        <p className=''>
                            <b className='code-color'>  VALUES </b>
                        </p>
                        <p className='tabulacion'>
                            (@userEmail,@userPassword,@userName));
                        </p>
                        <p className=''>
                            <b className='code-color'>  GO </b>
                        </p>
                    </div>
                </div>


                <div>
                    <h3>Estructura de sentencias SQL</h3>
                    <p className=''>
                        Para buenas prácticas es recomendable que las sentencias sql tengan una estructura limpia y legible, donde las variables que se declaren o hasta el mismo nombre del procedimiento
                        ayudarían nombrandolos de una manera clara y entendible, una forma podría ser implementando los principios SOLID que se utiliza para los lenguajes de programación.
                    </p>


                </div>

                {/*     Código */}
                <div className='code-2'>
                    {/*          Inormacion antes de indicar codigo */}
                    <span>Estructura del nombre para los procedimientos almacenados, preferentemente escritos en inglés:</span>
                    {/*       Poner el codigo en la etiqueta P con la className code*/}
                    <p className=''> {/*Centrar */}
                        Acción (Minúsculas) + Sustantivo (Primera letra en mayúscula) = acciónSustantivo
                    </p>
                    <span>Ejemplos: </span>
                    <span className=''>
                        Basándose en la terminología CRUD,
                    </span>
                    <p className='tabulacion'>
                        <b className='code-color'> createUser() </b> - - Creación de usuario
                        <br />
                        <b className='code-color'> readUsers()	</b> - - Lectura de usuarios
                        <br />
                        <b className='code-color'>  updateUser()</b>- - Actualización de usuario
                        <br />
                        <b className='code-color'>deleteUser()	</b> - - Eliminación de usuario
                    </p>


                    <p className=''>
                        Para evitar confusiones, es necesario ser lo mas especifico que sea posible,
                        por ejemplo, no es lo mismo   <b className='code-color'>  readUser()  </b> a
                        <b className='code-color'>  readUsers() </b> ,
                        ya que el primero se enfoca en traer solo un usuario y
                        en el segundo trae todos o un grupo de usuarios que se especifique.
                    </p>

                    <p className=''>
                        Por otro lado, para las variables podrían utilizarse dos estructuras,
                        se utilizan dependiendo de lo que se necesite.
                    </p>
                    <span>Estructura 1: </span>
                    <p className=''>Acción (Minúsculas) + Sustantivo
                        (Primera letra en mayúscula) = acciónSustantivo</p>
                    <span>Ejemplo: </span>
                    <p className='tabulacion'>
                        lastInsertUser       - - Variable que obtiene el ID del la última
                        inserción de un usuario.
                    </p>

                    <span>Estructura 2: </span>
                    <span>
                        En esta estructura va más enfocado a subatributos de una variable
                        principal, similar a los atributos de una tabla.
                    </span>

                    <p className=''>{/*Centrar */}
                        Sustantivo (Minúsculas) + Atributo (Primera letra en mayúscula) = acciónAtributo
                    </p>
                    <span>Ejemplos: </span>
                    <p className='tabulacion'>
                        userName- - Nombre del usuario
                    </p>
                    <p className='tabulacion'>
                        userLastName - - Apellido del usuario
                    </p>
                    <p className='tabulacion'>
                        userEmail {' '}	- - Correo del usuario
                    </p>

                    <p className=''>
                        Para una mayor flexibilidad de las sentencias SQL,
                        puede ayudar la organización de estas mismas,
                        sobre todo cuando se tienen varias sentencias simultáneas.
                    </p>

                    <span>Ejemplos: </span>
                    <span>
                        Organizar las sentencias de esta manera puede que
                        al principio no dificulta la legibilidad y entendimiento,
                    </span>

                    <p className=''>
                        INSERT INTO users (user_email,user_password,user_name)
                    </p>
                    <p className=''>
                        VALUES (userEmail,userPassword,userName);
                    </p>

                    <span>
                        Pero en el momento que aumenta el número de parámetros, cambian un poco las cosas.
                    </span>

                    <p className=''>
                        <b className='code-color'>   INSERT INTO </b> users (user_email,user_password,user_name, user_lastname,user_age,user_ phone,user_rol)
                    </p>
                    <p className=''>
                        <b className='code-color'>   VALUES  </b>
                        (userEmail,userPassword,userName,
                        userLastName,userAge, userPhone, userRol)
                    </p>

                    <span>
                        Por ende, se pueden organizar de esta manera:
                    </span>

                    <p className=''>
                        <b className='code-color'>   INSERT INTO </b>   users
                    </p>
                    <p className='tabulacion'>
                        (user_email,user_password,user_name, user_lastname,user_age,user_ phone,user_rol)
                    </p>
                    <p className=''>
                        <b className='code-color'>   VALUES  </b>
                    </p>
                    <p className='tabulacion'>
                        (userEmail,userPassword,userName,
                        userLastName,userAge, userPhone, userRol)
                    </p>

                    <span>
                        Y en el caso de tener bastantes atributos:
                    </span>

                    <p className=''>
                        <b className='code-color'> INSERT INTO  </b>users

                    </p>
                    <p className='tabulacion'>
                        &#39;    {'(user_email,'} &#39;
                        <br />
                        &#39;  {'user_password,'}&#39;
                        <br />
                        &#39; {'user_name,'} &#39;
                        <br />
                        &#39;  {'user_lastname,'} &#39;
                        <br />
                        &#39;   {'user_age,'}&#39;
                        <br />
                        &#39;  {'user_ phone'}&#39;
                        <br />
                        &#39;  {'user_rol)'}&#39;
                    </p>
                    <p className=''>
                        <b className='code-color'>   VALUES  </b>
                    </p>
                    <p className='tabulacion'>
                        &#39;    {'(userEmail,'}     &#39;
                        <br />
                        &#39;    {'userPassword,'}     &#39;
                        <br />
                        &#39;    {'userName,'}     &#39;
                        <br />
                        &#39;    {'userLastName,'}     &#39;
                        <br />
                        &#39;    {'userAge,'}     &#39;
                        <br />
                        &#39;    {'userPhone'}     &#39;
                        <br />
                        &#39;     {'userRol)'}     &#39;

                    </p>

                    <span>
                        En el caso de otro tipos de sentencias:
                    </span>
                    <span>
                        Consultas
                    </span>

                    <p className=''>
                        <b className='code-color'>   SELECT</b>    (user_email,user_password,user_name) FROM users
                    </p>
                    <p className=''>
                        <b className='code-color'>    ORDER </b>     BY user_name ;
                    </p>

                    <span>
                        Actualizaciones
                    </span>

                    <p className=''>
                        <b className='code-color'>    UPDATE</b>    usuarios SET
                    </p>
                    <p className='tabulacion'>
                        user_name = userName,
                    </p>
                    <p className='tabulacion'>
                        user_lastname = userLastName,
                    </p>
                    <p className='tabulacion'>
                        user_password = userPassword,
                    </p>
                    <p className='tabulacion'>
                        user_Email  = userEmail,
                    </p>
                    <p className=''>
                        <b className='code-color'> WHERE </b>user_id = userId;
                    </p>

                    <span>
                        Por último, es recomendable que la sentencia para un procedimiento almacenado se inicialice con CREATE OR REPLACE,
                        de igual manera con fines de buenas prácticas y en caso de que se cree directamente con puro código SQL, ya que en
                        las interfaces de los gestores cuentan con opciones donde al crear un procedimiento, lo crea de esta manera, en el
                        caso de que exista y se pueda actualizar correctamente.
                    </span>

                    <br />
                    <br />
                </div>
            </div>
            <div>
            </div>
        </div>
    )
}
export default ProcedimientosAlmacenados;