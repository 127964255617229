import React from "react";
import FooterSmall from "./components/FooterSmall";
import Logo from "../assets/img/log.png";

function NotFound() {
  return (
    <div className="not-found">
      <div className="log">
        <img src={Logo} alt="DELFOS369" />
      </div>
      <h2>
        La Dirección de la Página web NO EXISTE
        <br /> verifica el Url o visita delfos369.com
      </h2>

      <FooterSmall />
    </div>
  );
}

export default NotFound;
