import React from 'react'
import "./productos.css"
import Responsive from "../../assets/img/productos/responsive.png"
import Autogestivo from "../../assets/img/productos/autogestivo.png"
import Nube from "../../assets/img/productos/nube.png"

function Medcur() {
  return (
    <div className='productos'>

      <div className="container">
        <div className="box">
          <div className="title">
            <span className="block"></span>
            <h1 >MEDCUR<span></span></h1>
          </div>
          <div className="role">
            <div className="block"></div>
            <p>Delfos369</p>
          </div>
        </div>
      </div>
      <div className='medcur'>

        <div className='informacion  imagen'>
          <img className="zoom" src={Responsive} alt="gestor Medico" />
        </div>
        <div className='responsive  medcur'>
          <h2>Responsivo</h2>
          <p> <b> Medcur</b> cuenta con una interfaz responsiva lo cual significa que
            se adapta a cualquier dispositivo (Televisión , Laptos, Pc's , Tabletas y Smarphones),
            además su diseño permite que la experiencia del usuario sea agradable al proveer interfaces intuitivas
            y comodas para navegar por todo el sistema.
          </p>
          <div>
          </div>
        </div>

      </div>


      <div className='medcur'>


        <div className='responsive  medcur'>
          <h2>Autogestivo</h2>
          <p> Gestiona las diferentes ventanas de una forma dinamica y efectiva.
          </p>
          <div>
          </div>
        </div>

        <div className='informacion  imagen'>
          <img className="zoom" src={Autogestivo} alt="gestor Medico" />
        </div>

      </div>



      <div className='medcur'>



        <div className='informacion  imagen'>
          <img className="zoom" src={Nube} alt="gestor Medico" />
        </div>


        <div className='responsive  medcur'>
          <h2>Escalable con Técnología en la Nube</h2>
          <p>
          </p>
          <div>
          </div>
        </div>

      </div>







    </div>
  )
}

export default Medcur