import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../css/Menu.css";
import logo from "../../../assets/img/logo.png";
import { Icon } from "@iconify/react";

function Menu() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <div className="header">
      <nav className="navbar">
        {/* ================================================ Logo ================================================ */}

        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo} alt="delfos369"></img>
        </Link>
        {/* ========================================== Menu Navegación ========================================== */}
        <div className="menu-icon" onClick={handleClick}>
          <Icon
            className="list"
            icon={click ? "ep:close-bold" : "charm:menu-hamburger"}
          />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              <p>Home</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/nosotros"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <p>Nosotros</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/servicios"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <p>Servicios</p>
            </Link>
          </li>
         {/*  <li className="nav-item">
            <Link
              to="/soluciones"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <p>Soluciones</p>
            </Link>
          </li>
 */}
  

          <li className="nav-item">
            <Link
              to="/comunidad"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <p>Comunidad</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/productos"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <p>Productos</p>
            </Link>
          </li>


          <li className="nav-item contacto">
            <Link
              to="/contacto"
              className="nav-links  "
              onClick={closeMobileMenu}
            >
              <p>Contacto</p>
            </Link>
          </li>
        </ul>
      </nav>
      {/* ============================================== Whatsapp ============================================== */}
      <div className="whatsapp">
        <a
          href="https://wa.me/+5215568148078/?text=Hola"
          target="_blank"
          rel="noreferrer"
        >
          <Icon icon="logos:whatsapp-icon" width={"50px"} />
        </a>
      </div>
    </div>
  );
}

export default Menu;
