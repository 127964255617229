
import "./css/contacto.css";
import React, { useState } from "react";
import axios from "axios";
import FooterSmall from "./components/FooterSmall";
import { mainRoute } from "./environments";

export function Contacto() {
  const [datos, setDatos] = useState({
    nombre: "",
    email: "",
    numero: "",
    texto: "",
  });

  const handleInputChange = (event) => {
    //console.log(event.target.value)
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  async function verificar() {
    let forms = {
      name: datos.nombre,
      email: datos.email,
      phone: datos.numero,
      text: datos.texto,
    };

    try {
      let response = await axios.post(`${mainRoute.public}email/contact`,forms);
      console.log('Envio de información, respuesta: ',response)
      console.log(forms);
      // let send2 = send.data;
      // console.log(send2);
      alert(response.msg);
    } catch (error) {
      console.error(error);
    }
  }
  const enviarDatos = (event) => {
    verificar();
    event.preventDefault();
    //guardado de datos
    event.target.reset();
  };

  return (

    <div className="box-contacto-home">

      <div className="container">
        <div className="box">
          <div className="title contacto">
            <span className="block"></span>
            <h1 >Contacto<span></span></h1>
          </div>
          <div className="role">
            <div className="block"></div>
            <p>Delfos369.</p>
          </div>
        </div>
      </div>


      <div className="box-contacto">

        <div className="content-contacto">
          <form action="" method="post" id="form" onSubmit={enviarDatos}>
            <div className="form">
              <div className="grupo">
                <label htmlFor="">Nombre</label>
                <input
                  type="text"
                  id="name"
                  name="nombre"
                  onChange={handleInputChange}
                  required
                />

              </div>
              <div className="grupo">
                <label htmlFor="">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleInputChange}
                  required
                />


              </div>
              <div className="grupo">
                <label htmlFor="">Teléfono</label>
                <input
                  type="tel"
                  name="numero"
                  id="telefono"
                  pattern="[0-9]{10}"
                  onChange={handleInputChange}
                  required
                />
                <span className=""></span>

              </div>
              <div className="grupo">
                <label htmlFor="">Escríbenos</label>
                <textarea
                  name="texto"
                  cols="40"
                  rows="5"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>

              <div className="boton-formulario">
                <button  type="submit"> Enviar </button>
              </div>
              <span className=""></span>

              <br />
            </div>
          </form>
        </div>
      </div>


      <div>
        <FooterSmall />
      </div>
    </div>
  );
}
