import * as React from 'react';
import { Button, Card } from 'react-bootstrap';
import Sol from "../../assets/img/productos/nube.png"

function Productos() {
    return (
        <div className='productos'>
            <div className="container">
                <div className="box">
                    <div className="title">
                        <span className="block"></span>
                        <h1 >Productos<span></span></h1>
                    </div>
                    <div className="role">
                        <div className="block"></div>
                        <p>Delfos369</p>
                    </div>
                </div>
            </div>
            <h2>Promovemos la integración de tecnologías a través de la experiencia e innovación</h2>
            <p>Nuestro equipo altamente especializado, nos permite implementar,
                optimizar y operar,tomando como principios la  calidad y mejores practicas </p>


            <div className=''>


                <div className='beneficios'>
                    <div className="card-servicios productos">
                        <h3>centro de datos</h3>
                        <div className="icon-servicios  productos">
                            <img src="https://cdn.pixabay.com/photo/2020/04/08/02/57/switch-5015530_960_720.jpg"></img>
                            <div className='btn-productos'>


                                <a type='button' href='/centro-datos'>Leer más</a>
                            </div>

                        </div>



                    </div>
                </div>



                <div className='beneficios'>
                    <div className="card-servicios productos">
                        <h3>Soluciones de  red de datos</h3>
                        <div className="icon-servicios  productos">
                            <img src="https://cdn.pixabay.com/photo/2014/03/13/01/12/datacenter-286386_960_720.jpg"></img>
                            <div className='btn-productos'>
                                <a type='button' href='/soluciones-red'>Leer más</a>
                            </div>

                        </div>



                    </div>
                </div>

                <div className='beneficios'>
                    <div className="card-servicios productos">
                        <h3>Soluciones de reuniones y comunicación corporativa</h3>
                        <div className="icon-servicios  productos">
                            <img src="https://cdn.pixabay.com/photo/2017/09/22/18/11/cyber-security-2776600_960_720.jpg"></img>
                            <div className='btn-productos'>
                                <a type='button' href='/soluciones-corporativa'>Leer más</a>
                            </div>

                        </div>



                    </div>
                </div>

                <div className='beneficios'>
                    <div className="card-servicios productos">
                        <h3>Soluciones de ciberseguridad</h3>
                        <div className="icon-servicios  productos">
                            <img src="https://cdn.pixabay.com/photo/2018/06/19/10/01/internet-3484137_960_720.jpg"></img>

                            <div className='btn-productos'>
                                <a type='button' href='/soluciones-ciberseguridad'>Leer más</a>
                            </div>

                        </div>



                    </div>
                </div>




                <div className='beneficios'>
                    <div className="card-servicios productos">
                        <h3>Soluciones de seguridad física</h3>
                        <div className="icon-servicios  productos">
                            <img src="https://cdn.pixabay.com/photo/2017/09/25/11/55/cyberspace-2784907_960_720.jpg"></img>

                            <div className='btn-productos'>
                                <a type='button' href='/soluciones-seguridad'>Leer más</a>
                            </div>

                        </div>



                    </div>
                </div>




                <div className='beneficios'>
                    <div className="card-servicios productos">
                        <h3>Soluciones de analítica</h3>
                        <div className="icon-servicios  productos">
                            <img src="https://cdn.pixabay.com/photo/2015/09/05/21/13/analytics-925379_960_720.jpg"></img>

                            <div className='btn-productos'>
                                <a type='button' href='/soluciones-analitica'>Leer más</a>
                            </div>

                        </div>



                    </div>
                </div>



                <div className='beneficios'>
                    <div className="card-servicios productos">
                        <h3>Noc</h3>
                        <div className="icon-servicios  productos">
                            <img src="https://cdn.pixabay.com/photo/2019/01/30/07/45/web-3963945_960_720.jpg"></img>

                            <div className='btn-productos'>
                                <a type='button' href='/noc'>Leer más</a>
                            </div>

                        </div>



                    </div>
                </div>




                <div className='beneficios'>
                    <div className="card-servicios productos">
                        <h3>Gobierno digital</h3>
                        <div className="icon-servicios  productos">
                            <img src="https://cdn.pixabay.com/photo/2019/04/14/18/50/forward-4127468_960_720.jpg"></img>

                            <div className='btn-productos'>
                                <a type='button' href='/gobierno-digital'>Leer más</a>
                            </div>

                        </div>



                    </div>
                </div>




            </div>




        </div>
    )
}

export default Productos