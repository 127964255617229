import React from 'react'
import "./blog.css"
import RutasIMG from "../../assets/img/blog/rutasprivadas.png"
function RutasPrivadas() {
    return (
        <div className='blog'>
            {/* ============================================ Inicio Curso ============================================ */}
            {/* ========================================== Titulo del Curso ========================================== */}
            <div className='titulo'>
                <h2>Rutas públicas y privadas en React js</h2>
            </div>

            <div className='box-info'>
                <div className='informacion'>
                    {/* ======================== Imagen de Portada - > Importar dento de etiqueta img ======================== */}
                    <div className='imagen'>
                        <img src={RutasIMG} alt="rutas privadas" />
                        {/* ============================ Información ; Nombre y Fecha de Publicacion ============================ */}
                        <div className='info'>
                            <span>By: Oscar Chávez </span>
                            <p>Publicado el 21 de diciembre del 2022</p>
                        </div>
                    </div>
                    <br />
                </div>

                <div className='requerimientos'>
                    <h2>Requerimientos</h2>
                    {/* ============================= Lista de requerimientos en etiquetas <li> ============================= */}
                    <div className='lista'>
                        <ol >
                            <li>Node Js</li>
                            <li>Npm</li>
                        </ol>
                    </div>

                    {/* ========== Poner Texto relevante dentro de la etiqueta  P  - Titulos , Subtitulos <li><li> ========== */}
                    <p >Paso 1: Iniciar un nuevo proyecto en React</p>
                    {/* =========================== Poner texto relevante dentro de la etiqueta P =========================== */}
                    <span>Para ello  abrimos una terminal y ponemos los siguientes
                        comando en el siguiente orden.</span>
                    <br />
                    {/*  Poner el codido dentro del div code , usando <span> para la informacion y <code>     cuando se pondra codigo  */}
                    <div className='code'>
                        <span>Comando para crear proyecto en donde "my-app" es el nombre del proyecto</span>
                        <code>npx create-react-app my-app</code>
                        <br />
                        <span>Comando para entrar a la carpeta del proyecto</span>
                        <code> cd my-app</code>
                        <br />
                        <span>Comando para iniciar el proyecto</span>
                        <code> npm start</code>
                        <br />
                        <span>Una vez iniciado el proyecto el siguiente paso es crear los diferetes
                            roles para las rutas privadas y públicas
                            es decir, cualquier usuario podra visualizar la página siempre y cuando los
                            componente llamados esten de forma pública
                            de lo contrario no podra visualizar los componentes si estos se encuentran
                            en rutas privadas.
                        </span>
                    </div>
                </div>
            </div>

            {/* ==================================== Inicio del Curso o Articulo  ==================================== */}
            {/* ======================================= Pantalla No dividida  ======================================= */}

            <div className='inicio-tutorial'>
                <div>
                    <h3>Creación Validación de Rol</h3>
                    {/* =  Si se desea cambiar de color la informacion de la etiqueta p poner en className grees, red, blue = */}
                    <p className=''>
                        Ahora creamos un archivo llamado ValidaRol.jsx el cual contendrá la validación de
                        los roles, para este ejemplo crearemos 2 roles privados y uno público, supongamos que nuestra
                        aplicación tiene diferentes menús para los roles privados y uno para todo el público en general,
                        al iniciar la aplicación se iniciará con un rol visible para todo el público pero una vez logueandos y
                        validando la información, este accederá al menú privado que se halla validado.
                    </p>
                </div>

                <div className='code-react'>
                    <code>

            /* ValidaRol.jsx*/
                        <br />
                        <br />
                        <code className='identificador'>import</code>    <code className='reservada'>React</code>      <code className='identificador'>from</code>  <code className='atributo'>"react"</code>;
                        <br />
                        <code className='identificador'> export </code> <code className='tipo'> let  </code>   <code className='reservada'> i  </code> =  <code className='atributo'> "" </code>; /* Variable para comparar tipo de rol*/
                        <br />
                        <code className='identificador'> export </code> <code className='tipo'> let  </code>   <code className='reservada'> validator  </code> =  <code className='atributo'> "rol1" </code>  ; /*  Variable que identifica el rol logueado*/
                        <br />

                        <code className='identificador'> export </code> <code className='tipo'> function   </code>   <code className='funcion'>  verificaRol()&#123;  </code>

                        <br />
                        <code className='identificador'>if</code>    (   <code className='reservada'>validator</code>  === <code className='atributo'> "rol1" </code>)	&#123;
                        <br />
                        <code className='reservada'> i  </code> =<code className='atributo'> "rol1" </code>;
                        <br />
                        &#125; <code className='identificador'>else if</code>    (   <code className='reservada'>validator</code>  === <code className='atributo'> "rol2" </code>)	&#123;
                        <br />
                        <code className='reservada'> i  </code> =<code className='atributo'> "rol2" </code>;
                        <br />
                        &#125;   <code className='identificador'> else  </code> 	&#123;
                        <br />
                        <code className='reservada'> i  </code> =<code className='atributo'> "public" </code>;

                        <br />
                        &#125;
                        <br />
                        <code className='funcion'> &#125;</code>
                        <br />

                        <code className='funcion'> verificaRol();  </code>

                        <br />
                        <br />
                        <code className='identificador'> export default </code>  <code className='tipo'> class  </code> ValidaUsuario  <code className='tipo'> extends  </code> React.Component &#123;
                        <br />
                        <code className='funcion'> render()  	&#123; </code>
                        <br />

                        return (

                        &lt;&gt;
                        &lt;/&gt;)
                        <br />
                        <code className='funcion'>  &#125; </code>
                        <br />
                        &#125;
                    </code>
                </div>

                <div>
                    <br />
                    <h3>Creación de Roles</h3>
                    <p className=''>
                        Una vez creada la validacion lo que sigue es crear los 3 roles que estaremos usando,
                        la logica para cada archivo es la misma, crearemos un rol público, Rol1 y Rol2, para ello
                        creamos 3 archivos con sus respectivos nombres, en este ejemplo solo mostraré uno.
                    </p>
                </div>


                <div className='code-react'>
                    <code> /*Rol1.jsx      Rol2.jsx       Public.jsx             */</code>
                    <br />
                    <br />
                    <code className='identificador'>import</code>    <code className='reservada'>React</code>      <code className='identificador'>from</code>  <code className='atributo'>"react"</code>;
                    <br />
                    <code className='identificador'> import </code>    <code className='reservada'>	&#123;  Redirect, Route  &#125; </code> <code className='identificador'>from</code>   <code className='atributo'> "react-router" </code>; <code>/*Importamos react-router dom  V. 5.3.0*/</code>
                    <br />
                    <code className='identificador'> import </code>    <code className='reservada'>	ValidaRol </code> <code className='identificador'>from</code>   <code className='atributo'> "../Validacion/ValidaRol" </code>; <code> /*Importamos el archivo anteriormente creado*/</code>
                    <br />
                    <code className='identificador'> import </code>    <code className='reservada'>	&#123;  i  &#125; </code> <code className='identificador'>from</code>    <code className='atributo'> "../Validacion/ValidaRol" </code>; <code> /*Importamos la variable i*/</code>
                    <br />
                    <code className='tipo'>let</code>     <code className='reservada'>auth</code>  =   <code className='tipo'>true</code>;
                    <br />
                    <code className='identificador'>if</code>    (   <code className='reservada'>i</code>  === <code className='atributo'> "rol1" </code>)	&#123;
                    <br />
                    <code className='reservada'> auth  </code> =<code className='tipo'> true </code>;
                    <br />
                    &#125;   <code className='identificador'> else  </code> 	&#123;
                    <br />
                    <code className='reservada'> auth  </code> =<code className='tipo'> false </code>;
                    <br />
                    &#125;
                    <br />
                    <br />
                    <code className='tipo'> const </code> <code className='funcion'>Rol1 =</code>  <code>( &#123;  <code className='reservada'> component: Component, ...rest </code> &#125; )= &gt;</code>
                    <br />
                    <code> &lt;ValidaRol/&gt;</code>
                    <br />
                    <code className='identificador'>if</code>    (   <code className='reservada'>i</code>  === <code className='atributo'> "rol1" </code>)	&#123;
                    <br />
                    <code className='identificador'>return </code> (
                    <br />

                    <code> &lt;Route &#123; ...rest  &#125;    &gt;
                        <br />
                        &#123; <code className='reservada'>auth </code> ?   <code> &lt;Component/&gt;</code> :    <code> &lt;Redirect to="/no-found-page"    /&gt;  &#125;</code>   <code>/*Redirigir si no se comprueba el rol*/</code>
                        <br />
                        <code> &lt;Route/&gt;</code>
                    </code>

                    );
                    <br />
                    &#125;   <code className='identificador'> else  </code> 	&#123;
                    <br />
                    <code className='identificador'> return  </code> =<code className='tipo'> null </code>;
                    <br />
                    &#125;
                    &#125;;
                    <br />
                    <code className='identificador'>export default</code>  <code className='funcion'>Rol1;</code>
                </div>


                <div>
                    <br />
                    <h3>Creación de Páginas para los diferentes roles</h3>
                    <p className=''>
                        Una vez creado los 3 archivos con los diferente roles, lo que sigue es crear las páginas que veran los distintos roles,
                        es decir, para el rol1 , creamos sus interfaces, para el rol2 el mismo caso y para el rol de rutas públicas.
                        <br />
                        Creamos un archivo de ejemplo para el menú privado 1, y hacemos lo mismo para el menú privado 2 y para el menú público, con el
                        fin de verificar que las rutas privadas funcionan correctamente.
                    </p>
                    <br />
                    <div className='code-react'>
                        <code>
                            <code className='identificador'>import</code>    <code className='reservada'>React</code>      <code className='identificador'>from</code>  <code className='atributo'>"react"</code>;
                            <br />
                            <code className='tipo'> function   </code>   <code className='funcion'>  MenuPrivado1()&#123;  </code>

                            <br />
                            <code className='identificador'> return  </code> (
                            <br />
                            &lt;div&gt;
                            <br />
                            &lt;h1&gt;  Soy el Menú Privado 1     &lt;/h1&gt;
                            <br />
                            &lt;/div&gt;
                            <br />
                            )
                            <br />
                            &#125;

                            <code className='identificador'> export default </code>  <code className='funcion'> MenuPrivado1  </code>
                        </code>
                    </div>

                </div>

                <div>
                    <br />
                    <h3>Test de las Rutas Privadas</h3>
                    <p className=''>
                        Ahora una vez creado todos los archivos necesarios, proseguimos con el test,
                        en donde verificaremos que funcionan todas las rutas.
                        <br />
                        Nos vamos al archivo App.js e importamos nuestros archivos  como se muestra a continuación
                    </p>
                    <br />


                    <div className='code-react'>
                        <code>
                            <code className='identificador'> import </code>    <code className='reservada'>	&#123;  BrowserRouter as Router  &#125; </code> <code className='identificador'>from</code>   <code className='atributo'> "react-router-dom" </code>; <code>/*Importamos react-router dom  V. 5.3.0*/</code>
                            <br />
                            <code className='identificador'> import </code>    <code className='reservada'>	Public </code> <code className='identificador'>from</code>   <code className='atributo'> "./Roles/Public" </code>;
                            <br />
                            <code className='identificador'> import </code>    <code className='reservada'>	Rol1 </code> <code className='identificador'>from</code>   <code className='atributo'> "./Roles/Rol1" </code>;
                            <br />
                            <code className='identificador'> import </code>    <code className='reservada'>	Rol2 </code> <code className='identificador'>from</code>   <code className='atributo'> "./Roles/Rol2" </code>;
                            <br />
                            <code className='identificador'> import </code>    <code className='reservada'>	MenuPrivado1 </code> <code className='identificador'>from</code>   <code className='atributo'> "./Paginas/MenuPrivado1" </code>;  /*Importamos la Pagina para el menú 1*/
                            <br />
                            <code className='identificador'> import </code>    <code className='reservada'>	MenuPrivado2</code> <code className='identificador'>from</code>   <code className='atributo'> "./Paginas/MenuPrivado2" </code>; /*Importamos la Pagina para el menú 2*/
                            <br />
                            <code className='identificador'> import </code>    <code className='reservada'>	MenuPublico</code> <code className='identificador'>from</code>   <code className='atributo'> "./Paginas/MenuPublico" </code>; /*Importamos la Pagina para el menú Público*/
                            <br />

                            <code className='tipo'> function   </code>   <code className='funcion'> App()&#123;  </code>

                            <br />
                            <code className='identificador'> return  </code> (
                            <br />
                            &lt;div&gt;
                            <br />
                            &lt;Router&gt;
                            <br />
                            &lt;Public <code className='reservada'> path</code> ="/"<code className='reservada'> component</code> =&#123; <code className='funcion'>MenuPublico</code>  &#125; /&gt;
                            <br />
                            &lt;Rol1 <code className='reservada'> path</code> ="/" <code className='reservada'> component</code> =&#123; <code className='funcion'>MenuPrivado1</code>  &#125; /&gt;
                            <br />
                            &lt;Rol2 <code className='reservada'> path</code> ="/"  <code className='reservada'> component</code> =&#123; <code className='funcion'> MenuPrivado2</code>  &#125; /&gt;
                            <br />
                            &lt;/Router&gt;
                            <br />
                            &lt;/div&gt;
                            <br />
                            )
                            <br />
                            &#125;

                            <code className='identificador'> export default </code>  <code className='funcion'> App </code>
                        </code>
                    </div>

                </div>
                <div>
                    <br />
                    <h3>Conclusiones</h3>
                    <p className=''>
                        Si bien no hay mucha información sobre las rutas privadas, este post podría serviles de mucha ayuda, algunos inconvenientes
                        con react js y que podría afectar son : la version del react router dom, ya que hay nuevas versiones en las cuales cambia en
                        algunas cosas y que podria afectar si se maneja una version reciente para hacer este ejemplo.
                    </p>
                    <p>Este ejemplo se encuentra disponible en el repositorio de delfos369, en la rama rutas-privadas
                    </p>


                </div>

                <div className='code-react'>

                    <code className='tipo'>https://github.com/delfos369/Base-de-conocimiento.git</code>

                </div>

                <br />

                <br />

                <br />

                <br />

            </div>







        </div>
    )
}

export default RutasPrivadas