import React from "react";
import "./css/Privacidad.css";

function AvisoPrivacidad() {
  return (
    <div className="section">
      <div className="aviso">
        <div className="privacidad">
          {/* =============================================== Titulo =============================================== */}
          <div className="container b">
            <div className="box">
              <div className="title">
                <span className="block"></span>
                <h1>
                  Aviso de Privacidad<span></span>
                </h1>
              </div>
              {/* ============================================= Sub-titulo ============================================= */}
              <div className="role">
                <div className="block"></div>
                <p>Delfos369</p>
              </div>
            </div>
          </div>
          {/* ============================================== Parrafo ============================================== */}
          <div className="parrafo1">
            <article className="parrafo">
              <p className="negritas">DELFOS369 S.A. DE C.V. </p> (en lo
              sucesivo <p className="negritas">“DELFOS369”</p>) dando
              cumplimiento a la Ley Federal de Protección de Datos Personales en
              Posesión de los Particulares (LFPDPPP), declara que el tratamiento
              legítimo, controlado e informado de sus datos personales es un
              compromiso de vital importancia; por lo tanto,{" "}
              <p className="negritas">“DELFOS369”</p> respeta la privacidad y la
              confidencialidad de nuestros clientes, prospectos, candidatos,
              empleados, ex-empleados y proveedores, conscientes de los derechos
              que usted tiene en relación con la información personal que
              recopilamos sobre su persona. <span className="nowrap"></span>
            </article>
            <article className="parrafo">
              El presente Aviso de Privacidad tiene como objeto informarle sobre
              el tratamiento que se les dará a todos los datos personales
              (incluyendo los datos sensibles) recabados, utilizados,
              almacenados y/o transferidos por{" "}
              <p className="negritas"> DELFOS369</p>a través de los diferentes
              medios como son: electrónicos, formatos impresos, entrevistas,
              circuito cerrado de televisión, entre otros, mismos que son y
              serán utilizados única y exclusivamente para los fines laborales y
              de negocios para los cuales fueron recabados.{" "}
              <span className="nowrap"></span>
            </article>
            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>Responsable del Tratamiento de sus Datos Personales.</p>
              <br />
            </div>
            {/* ============================================== Parrafo ============================================== */}
            <article className="parrafo">
              <p className="negritas"> DELFOS369 </p>, con domicilio en Avenida
              Santa Fe 505, Piso 1, Mezzanine 2B, Colonia Santa Fe, Alcaldía
              Cuajimalpa de Morelos, CP. 05348, Ciudad de México, será el
              responsable de velar por la privacidad de sus datos personales,
              cuando los mismos sean recabados, utilizados, almacenados y/o
              transferidos por <p className="negritas">DELFOS369</p> .{" "}
              <span className="nowrap"></span>
            </article>
            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>Circuito Cerrado de Televisión.</p>
              <br />
            </div>

            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>Datos Personales que se Recaban.</p>
              <br />
            </div>
            {/* ============================================== Parrafo ============================================== */}
            <article className="parrafo">
              <p className="negritas">DELFOS369</p> podrá recabar y en su caso,
              tratar los siguientes datos personales dependiendo de la relación
              que con usted exista: <span className="nowrap"></span>
            </article>

            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p className="sub">Datos Generales.</p>
              <br />
            </div>

            <article className="parrafo">
              Nombre, fecha de nacimiento, edad, estado civil, domicilio,
              teléfono, correo electrónico, firma, RFC, CURP, nacionalidad,
              estado civil. <span className="nowrap"></span>
            </article>
            {/* =========================================== Titulo Parrafo =========================================== */}

            <div className="title">
              <br />
              <p className="sub">Datos Sensibles.</p>
              <br />
            </div>
            {/* ============================================== Parrafo ============================================== */}
            <article className="parrafo">
              Le informamos que, para cumplir con las finalidades previstas en
              este Aviso de Privacidad, serán recabados y tratados datos
              personales sensibles como: Datos laborales, patrimoniales,
              académicos, ideológicos, de salud, características personales,
              características físicas y diversiones, para lo cual nos
              comprometemos a que los mismos serán tratados bajo las más
              estrictas medidas de seguridad que garanticen su confidencialidad.
              No obstante, <p className="negritas">DELFOS369</p> podrá dar
              tratamiento a otros datos personales, sensibles y no sensibles, de
              conformidad con lo establecido en la Ley de Protección de Datos
              Personales en Posesión de Sujetos Obligados de la Ciudad de México
              que no se incluya en la lista anterior; siempre y cuando, dichos
              datos se consideren de la misma naturaleza y no sean excesivos
              respecto a las finalidades para las cuales se recaban.
              <span className="nowrap"></span>
            </article>

            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>Finalidades del Tratamiento de los Datos Personales.</p>
              <br />
            </div>
            {/* ============================================== Parrafo ============================================== */}
            <article className="parrafo">
              <p className="negritas"> DELFOS369</p> recaba, utiliza, almacena o
              transfiere sus datos personales, a través de los medios antes
              mencionados en la medida en que las leyes aplicables lo permiten,
              para llevar a cabo lo siguiente:
              <br />
              • Cumplir con obligaciones de carácter legal.
              <br />
              • Conformar expedientes de empleados, clientes y proveedores.
              <br />
              • Poder llevar a cabo la prestación del servicio requerido.
              <br />
              • Crear bases de datos de empleados, exempleados, clientes y
              proveedores.
              <br />
              <br />
              <p className="negritas">DELFOS369 </p>podrá hacer uso de sus datos
              personales para otras finalidades, siempre y cuando dichas
              finalidades sean compatibles y puedan considerarse análogas a las
              anteriores, en el entendido de que sus datos personales serán
              tratados únicamente por el tiempo necesario a fin de cumplir con
              las finalidades descritas y/o de conformidad con lo que
              establezcan las disposiciones legales aplicables.
              <span className="nowrap"></span>
            </article>

            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>Seguridad de los Datos Personales.</p>
              <br />
            </div>

            {/* ============================================== Parrafo ============================================== */}
            <article className="parrafo">
              <p className="negritas">DELFOS369</p> implementará las medidas de
              seguridad, administrativas y físicas, necesarias para proteger sus
              datos personales y evitar su daño, pérdida, alteración,
              destrucción o el uso, acceso o tratamiento no autorizado.
              Únicamente el personal autorizado que ha cumplido y observado los
              correspondientes requisitos de confidencialidad podrá participar
              en el tratamiento de sus datos personales. El personal autorizado
              tiene prohibido permitir el acceso de personas no autorizadas y
              utilizar sus datos personales para fines distintos a los
              establecidos en el presente Aviso de Privacidad. La obligación de
              confidencialidad de las personas que participan en el tratamiento
              de sus datos personales subsiste aún después de terminada la
              relación con <p className="negritas">DELFOS369</p> .
              <span className="nowrap"></span>
            </article>

            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>Comunicaciones y Transferencias de Datos Personales.</p>
              <br />
            </div>
            {/* ============================================== Parrafo ============================================== */}
            <article className="parrafo">
              Como parte de las operaciones empresariales llevadas a cabo por{" "}
              <p className="negritas">DELFOS369</p>, ésta podrá compartir con
              terceros, nacionales o extranjeros, algunos o todos sus datos
              personales. Dichos terceros podrán ser: Clientes, proveedores,
              asesores profesionales externos y/u otros prestadores de servicios
              que trabajen para <p className="negritas">DELFOS369</p>.
              <p className="negritas"></p>DELFOS369 buscará asegurarse que
              dichos terceros mantengan medidas de seguridad, administrativas,
              técnicas y físicas adecuadas para resguardar sus datos personales;
              así como que dichos terceros utilicen únicamente sus datos
              personales para las finalidades para las cuales fueron contratados
              y de conformidad con el presente Aviso de Privacidad.
              <p className="negritas">DELFOS369</p> no cederá o transferirá sus
              datos personales a terceros no relacionados con DELFOS369, sin su
              consentimiento previo; sin embargo,
              <p className="negritas">DELFOS369</p> podrá transferir sus datos
              personales recabados, incluyendo las imágenes obtenidas del
              circuito de video a las autoridades correspondientes en
              cumplimiento con requerimientos judiciales y de otras leyes
              vigentes en México y en los casos previstos en la Ley de
              Protección de Datos Personales en Posesión de Sujetos Obligados de
              la Ciudad de México.
              <span className="nowrap"></span>
            </article>

            {/* =========================================== Titulo Parrafo =========================================== */}

            <div className="title">
              <br />

              <p>
                Derechos que Corresponden a los Titulares de Datos Personales.
              </p>

              <br />
            </div>
            {/* ============================================== Parrafo ============================================== */}
            <article className="parrafo">
              Usted como titular de datos personales, podrá ejercitar los
              derechos de <p className="negritas sub">Acceso</p>, Rectificación,
              Cancelación y Oposición respecto al tratamiento de sus datos
              personales. Asimismo, podrá revocar en todo momento, el
              consentimiento que haya otorgado y que fuere necesario para el
              tratamiento de sus datos personales. A manera de referencia, a
              continuación, se describen los derechos de acceso, rectificación,
              cancelación y oposición: A través de su derecho de Acceso, usted
              podrá solicitar se le informe qué tipo de datos personales están
              siendo tratados por <p className="negritas">DELFOS369</p>, el
              origen de dichos datos y las comunicaciones que se hayan realizado
              con los mismos. Por virtud del derecho de{" "}
              <p className="negritas sub">Rectificación</p>, usted puede
              solicitar se corrijan o completen los datos personales que sean
              incorrectos o estén incompletos y que obren en manos de{" "}
              <p className="negritas">DELFOS369</p>. Usted tendrá la obligación
              de informar a <p className="negritas">DELFOS369</p> cuando deba
              hacerse un cambio o corrección en sus datos personales y este
              hecho sólo sea de su conocimiento. Cuando sus datos personales
              hayan dejado de ser necesarios para los fines para los cuales se
              recabaron, usted podrá solicitar que los mismos sean{" "}
              <p className="negritas sub">Cancelados</p>, si es que aún se
              conservaran en las bases de datos de{" "}
              <p className="negritas">DELFOS369</p>. El proceso de cancelación
              irá precedido de un período de bloqueo mediante el cual únicamente
              se almacenarán sus datos por un tiempo, equivalente al plazo de
              prescripción de las acciones que dieron origen al tratamiento de
              sus datos personales o el período que por ley se establezca.
              Existen casos en que los datos personales no pueden ser cancelados
              por disposición de ley. Podrá{" "}
              <p className="negritas sub">Oponerse</p> al tratamiento de sus
              datos personales, cuando exista una causa legítima para ello,
              incluso habiendo expresado anteriormente su consentimiento para
              dicho tratamiento. En virtud de lo anterior, si desea cualquier
              forma de acceso, rectificación, cancelación y/u oposición respecto
              de los datos personales con los que cuenta la organización, se
              podrá solicitar a través del correo electrónico{" "}
              <p className="azul">hola@delfos369.com </p> o en las oficinas de
              personal de <p className="negritas">DELFOS369</p>, quien le
              indicará el procedimiento a seguir.
              <span className="nowrap"></span>
            </article>

            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>Cambios al Aviso de Privacidad. </p>
              <br />
            </div>
            {/* ============================================== Parrafo ============================================== */}
            <article className="parrafo">
              <p className="negritas">DELFOS369</p> se reserva el derecho de
              enmendar o modificar el presente Aviso de Privacidad como estime
              conveniente; por ejemplo, para cumplir con cambios a la
              legislación sobre protección de datos o cumplir con disposiciones
              internas de la Organización.
              <p className="negritas">DELFOS369</p> le informará y pondrá a su
              disposición el Aviso de Privacidad actualizado, cuando se le hagan
              cambios significativos al mismo.
              <span className="nowrap"></span>
            </article>

            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>Fecha última actualización Febrero 2022 </p>
              <br />
            </div>

            {/* =========================================== Titulo Parrafo =========================================== */}
            <div className="title">
              <br />
              <p>DELFOS360 S.A. DE C.V. </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvisoPrivacidad;
