import React from 'react'
import "./productos.css"
import Responsive2 from "../../assets/img/productos/responsive2.png"
import Marvilop1 from "../../assets/img/productos/marvilop1.png"
import Marvilop2 from "../../assets/img/productos/marvilop2.png"


function Marvilop() {
  return (
    <div className='productos'>

      <div className="container">
        <div className="box">
          <div className="title">
            <span className="block"></span>
            <h1 >MARVILOP<span></span></h1>
          </div>
          <div className="role">
            <div className="block"></div>
            <p>Delfos369</p>
          </div>
        </div>
      </div>
      <div className='medcur'>

        <div className='informacion  imagen'>
          <img className="zoom" src={Responsive2} alt="software , programa , preventa y venta" />
        </div>
        <div className='responsive  medcur'>
          <h2>Responsivo</h2>
          <p> <b> Marvilop</b> cuenta con una interfaz responsiva lo cual significa que
            se adapta a cualquier dispositivo (Televisión , Laptos, Pc's , Tabletas y Smarphones),
            su diseño permite que la experiencia del usuario sea agradable al proveer interfaces intuitivas
            y comodas para navegar por todo el sistema.
          </p>
          <div>
          </div>
        </div>

      </div>


      <div className='medcur'>


        <div className='responsive  medcur'>
          <h2>Dinámico</h2>
          <p> Crea , Gestiona y Administra tus proyectos
            <br /> <b>Marvilop</b> te permite registrar proyectos con la capacidad
            de realizar el registro entre una y "n" personas al mismo tiempo, además de
            administrar diferente roles con funcionalidades diferentes acorde a las
            necesidades de cada empresa.
          </p>
          <div>
          </div>
        </div>

        <div className='informacion  imagen'>
          <img className="zoom" src={Marvilop1} alt="gestor preventa" />
        </div>

      </div>



      <div className='medcur'>



        <div className='informacion  imagen'>
          <img className="zoom" src={Marvilop2} alt="gestor Medico" />
        </div>


        <div className='responsive  medcur'>
          <h2>Portable</h2>
          <p> <b>Marvilop</b> cuenta con la caracteristica de cargar proyectos prellenados en
            formato excel, asi como también cuenta con la capacidad de exportar en el mismo formato
            de formma rapida y eficaz, exporta tus cotizaciones en PDF , Excel con plantilas personalizables
          </p>
          <div>
          </div>
        </div>

      </div>







    </div>
  )
}

export default Marvilop